import React from "react";
import ResumeTitle from "./ResumeTitle";
import { MdWork } from "react-icons/md";
import { GiGraduateCap } from "react-icons/gi";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <div className="w-full grid grid-cols-9 px-6">
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Experience" icon={<MdWork />} />
        <ResumeCard
          badge=""
          title="Data Collector"
          subTitle="IMG Arena"
          des="• Completed comprehensive training on the rules and usage of
          the company's software and hardware
          • Demonstrated attention to detail and accuracy in inserting
          Football and Basketball data into the system after attending
          various sport matches.
          • Attended several matches of Top Tier Leagues, such as Greek
          Super League, Basketball Euroleague, Eurocup.
          "
        />
        <ResumeCard
          badge="2022"
          title="Data Collector"
          subTitle="Sportlevel"
          des="• Completed comprehensive training on the rules and usage of
          the company's system
          • Demonstrated attention to detail and accuracy in inserting data
          into the system after attending various sport matches
          • Utilized strong organizational skills to efficiently manage and
          prioritize tasks
          • Contributed to the overall success of the company by providing
          reliable and accurate data collection services"
        />
        
      </div>
      <div className="w-full h-full hidden lgl:flex justify-center items-center">
        <span className="w-[1px] h-full bg-zinc-800 inline-flex"></span>
      </div>
      <div className="col-span-9 md:col-span-4">
        <ResumeTitle title="Education" icon={<GiGraduateCap />} />
        <ResumeCard
          badge="2020-Present"
          title="Statistics"
          subTitle="University Of Piraeus"
          des="Selected Path: Data Science /Data Analytics"
        />
        <ResumeCard
          badge="2023"
          title="Sports Analytics Course"
          subTitle="Workearly"
          des="Certified by Credly"
        />
        <ResumeCard
          badge="2017"
          title="ECPE English Proficiency"
          subTitle="Michigan University"
        />
        
      </div>
    </div> 
  );
};


export default Education;
