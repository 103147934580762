import React from "react";
import {
  workImgOne,
  workImgTwo,
  workImgThree,
  workImgFour,
  workImgFive,
  workImgSix,
  workImgSeven,
  workImgEight,
} from "../../assets";
import Title from "../home/Title";
import ProjectsCard from "./ProjectsCard";

const Projects = () => {
  return (
    <div>
      <Title title="Recent" subTitle="Projects" />
      <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10">
        <div className="px-6">
          <ProjectsCard
            title="Bank Marketing UCI"
            category="EDA, Python"
            image={workImgOne}
            link="https://github.com/mihalistsop/Bank-Marketing-UCI-EDA/blob/main/BankMarketingEDA.ipynb"
          />
          <ProjectsCard
            title="Manchester City 2022-23 Champions League Analysis"
            category="Tableau"
            image={workImgTwo}
            link="https://public.tableau.com/app/profile/michael.chop8124/viz/City_16885773317120/Dashboard2"
          />
          <ProjectsCard
            title="NBA Data Analysis"
            category="EDA, Python"
            image={workImgFive}
            link="https://github.com/mihalistsop/NBA-Analysis/blob/main/NBA.ipynb"

          />
          <ProjectsCard
            title="Analyzing a football match. Scrapping Data from FBRef with R & Visualizing with Tableau"
            category="R, Tableau"
            image={workImgThree}
            link="https://medium.com/@mihalistsop/analyzing-a-football-match-scrapping-data-from-fbref-with-r-visualizing-with-tableau-5c20f01bfafe"
          />
          
        </div>
        <div className="px-6">
          <ProjectsCard
            title="Exploring A Hospital's Database"
            category="SQL"
            image={workImgFour}
            link="https://medium.com/@mihalistsop/exploring-a-hospitals-database-with-sql-b3049ace5272"
          />
          <ProjectsCard
            title="Analyzing the Greek Football Derby"
            category="Microsoft Excel"
            image={workImgSix}
            link="https://medium.com/@mihalistsop/analyzing-the-greek-derby-with-microsoft-excel-a04e5c2dd472"
          />
          <ProjectsCard
            title="Inter Champions League 2022-23 Analysis"
            category="Tableau"
            image={workImgEight}
            link="https://public.tableau.com/app/profile/michael.chop8124/viz/InterChampionsLeague2022-23Analysis/Dashboard1"
          />
          <ProjectsCard
            title="Coming Soon"
            category="PowerBI"
            image={workImgSeven}
          />
        </div>
      </div>
    </div>
  );
};

export default Projects;
