import React from "react";
import Title from "../home/Title";
import { blogImgOne, } from "../../assets";
import BlogCard from "./BlogCard";

const Blog = () => {
  return (
    <div>
      <Title title="Latest" subTitle="Posts" />
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lgl:gap-10">
        <div className="px-6">
          <BlogCard
            image={blogImgOne}
            title="October 26, 2023"
            subTitle="A Model of Blockchain-Based Security in Insurance "
            category="Economics"
            link="https://www.aagora.gr/ena-montelo-asfalisis-pou-vasizetai-sto-blockchain/"
          />
        
        </div>
      </div>
    </div>
  );
};

export default Blog;
