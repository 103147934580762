import React from "react";
import { BiCodeAlt } from "react-icons/bi";
import ServicesCard from "./ServicesCard";

import { RiComputerLine } from "react-icons/ri";
import { GiArtificialIntelligence } from "react-icons/gi";
import { TbSoccerField } from "react-icons/tb";
import { PiSigmaThin } from "react-icons/pi";
import { MdOutlineBusinessCenter } from "react-icons/md";

const MyServices = () => {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2">
      <ServicesCard
        icons={<BiCodeAlt />}
        title="Data Science & Analytics"
        subTitle="
        • Exploratory Data Analysis
        • Microsoft Excel
        • Microsoft Power Bi
        • Python Libraries (Pandas, numpy etc)
        • Tableau"
      />
      <ServicesCard
        icons={<RiComputerLine />} 
        title="Computer Science"
        subTitle="
        • Python Programming Language
        • SQL Programming Language
        • Informative Systems Experience
        • C & R Experience
        • Linear Programming Experience"
      />
      <ServicesCard
        icons={<GiArtificialIntelligence />}
        title="Machine Learning"
        subTitle="• Classification
        • Clustering
        • Training ML Models"
      />
      <ServicesCard
        icons={<TbSoccerField />}
        title="Sports Analytics"
        subTitle="• Certified Sports Analytics Professional
        • Certified by Credly
        • Issued by Workearly"
      />
      <ServicesCard
        icons={<PiSigmaThin />}
        title="Mathematics"
        subTitle="• Calculus
        • Linear Algebra
        • Probabilities
        • Statistics
        • Stohastic Processes"
      />
      <ServicesCard
        icons={<MdOutlineBusinessCenter />}
        title="Economics & Business"
        subTitle="Sales Analytics
        Micro & Macroeconomics
        Dashboards
        Social Media
        Marketing"
      />
    </div>
  );
};

export default MyServices;
